body {
  margin: 0;
  font-family: 'Catamaran' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100vh;
}

.row {
  margin-right: 0 !important;
  margin-left: 0 !important;
  margin-top: 10px !important;
}

p {
  /* color: #818992; */
  /* text-align: center; */
  font-weight: 300p;
}

h2 {
  margin-bottom: 1.5rem;
}

h3 {
  font-size: 23px !important;
  font-weight: 700 !important;
}

.clicked-button {
  background: #c61515 !important;
}

.carousel-image {
  height: auto;
  width: 100%;
}

.clinic-service {
  background: #7811112b;
  padding: 1%;
  text-align: center !important;
}

.clinic-repair {
  padding: 5%;
  /* text-align: center !important; */
}

.clinic-about {
  padding: 5%;
  background: #7811112b;
  text-align: center !important;
}

.clinic-color {
  color: #c61515 !important;
}

.clinic-footer {
  padding: 5%;
  background: #791111;
}

.clinic-container {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.sidebar {
  margin: 0;
  padding: 0px 0px 0px 0px;
  width: 200px;
  background: #111827;
  position: fixed;
  height: 100%;
  overflow: auto;
}

.sidebar .subHead {
  cursor: pointer;
  display: block;
  color: #d6d6d6;
  padding: 7px;
  margin-bottom: 0;
}

.sidebar p {
  font-size: 13px;
  cursor: pointer;
  display: block;
  color: #d6d6d6;
  padding: 5px 7px 5px 15px;
  margin-bottom: 0;
}

.sidebar p.active {
  background-color: #ffffff;
  color: #111827;
}

.sidebar p:hover:not(.active) {
  background-color: #555;
  color: white;
}

div.content {
  background: #f9f9f9;
  margin-left: 200px;
  padding: 0px;
  height: 100%;
  overflow: scroll;
}

@media screen and (max-width: 700px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  .sidebar p {
    float: left;
  }

  div.content {
    margin-left: 0;
  }
}

@media screen and (max-width: 400px) {
  .sidebar p {
    text-align: center;
    float: none;
  }
}

.custom-shape-divider-top-1645216335 {
  /* position: absolute; */
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-top-1645216335 svg {
  /* position: relative; */
  display: block;
  width: calc(145% + 1.3px);
  height: 400px;
}

.custom-shape-divider-top-1645216335 .shape-fill {
  fill: url(#gradient2);
}

.navbar-light .navbar-nav .nav-link {
  color: white !important;
}

.navbar {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

/* .homePageImage:hover {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
} */

.table-rows:hover {
  background: #cccbe7;
}

.toggle-loader {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  padding: 50vh 0% 0% 50%;
}

#spnPrint {
  display: none;
}

#spnPrintThermal {
  display: none;
}

#mainDiv {
  display: flex;
  width: 80mm;
  margin-left: 20px;
  align-items: center;
}

@media print {

  #ProfitAndLost {
    /* @page { */
    size: 297mm 420mm;
    /* } */
  }

  #spnPrint {
    display: block !important;
  }

  #spnPrint * {
    visibility: visible !important;
  }

  #spnPrint {
    /* position: fixed !important; */
    z-index: 99999 !important;
    background: white;
    width: 100% !important;
    height: 100vh !important;
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
    font-size: 12px !important;
  }

  #spnPrintThermal {
    display: block !important;
  }

  #spnPrintThermal {
    break-inside: avoid;
  }

  #spnPrintThermal * {
    visibility: visible !important;
  }

  #spnPrintThermal {
    left: 0 !important;
    top: 0 !important;
    z-index: 99999 !important;
    background: white;
    width: 80mm !important;
    display: block !important;
    margin: 0px !important;
    margin-bottom: 0.5cm;
    height: max-content;
    box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
    font-size: 12px !important;
  }

  #spnPrintThermal>section {
    width: 95% !important;
    margin: auto !important;
  }

  /* body, #spnPrint[size="A4"] {
    box-shadow: 0;
} */
  #spnPrintLabel {
    width: 54mm !important;
    height: 25mm !important;
  }

  .main-prnthead {
    /* background-color: #263238 !important; */
    padding: 12px 14px !important;
    width: 78% !important;
    display: inline-block !important;
  }

  #spnPrintThermal .main-prnthead {
    width: 100% !important;
    padding: 0px 10px !important;
  }

  .main-prnthead h2 {
    margin: 3px;
    font-size: 23px;
    width: 70%;
    display: inline-block;
    color: rgb(0, 0, 0) !important;
  }

  #spnPrintThermal h2 {
    width: 50% !important;
    font-size: 15px;
  }

  .main-prnthead .spn-inv {
    display: inline-block;
    text-align: right;
    width: 25%;
    color: rgb(0, 0, 0) !important;
    font-size: 15px;
  }

  #spnPrintThermal .spn-inv {
    width: 47% !important;
    font-size: 14px;
  }

  .main-prnthead i.fa {
    background: #263238 !important;
    margin-right: 10px !important;
    color: rgb(0, 0, 0) !important;
  }

  .fa-file-invoice:before {
    color: rgb(0, 0, 0) !important;
  }

  .company-logo {
    width: 22%;
    /* display: inline-block; */
    float: right;
    text-align: center;
    font-weight: 500;
    margin-top: 5px;
  }

  .main-info {
    display: block !important;
    clear: both !important;
    width: 93% !important;
    margin: 16px auto !important;
  }

  #spnPrintThermal .main-info {
    width: 97% !important;
    margin: 11px auto !important;
    font-weight: 600 !important;
  }

  .lp-holder {
    width: 46% !important;
    float: left !important;
    display: flex;
    align-items: center;
    margin-left: -5px;
  }

  .rp-holder {
    width: 46% !important;
    float: right !important;
    display: flex;
    align-items: center;
    margin-right: 3px;
  }

  .lp-holder>.left-pane,
  .rp-holder>.left-pane {
    display: inline-block;
    min-width: 30% !important;
    width: 30% !important;
    /*float: left !important;*/
    font-weight: 600 !important;
    padding: 4px 0px !important;
  }

  .lp-holder>.right-pane,
  .rp-holder>.right-pane {
    display: inline-block;
    min-width: 70%;
    width: 70%;
    /*float: left !important;*/
    color: #797979 !important;
    font-weight: normal !important;
    border-bottom: 1px solid !important;
    padding: 4px 0px !important;
    padding-left: 6px !important;
  }

  .amount-holder {
    margin-left: 10px;
    padding: 20px 0px;
  }

  .sign-holder {
    padding: 20px 30px;
    text-align: right;
  }

  .amount-holder>.left-pane,
  .sign-holder>.left-pane {
    display: inline-block;
    min-width: 14%;
    text-align: right;
    font-weight: 600 !important;
    padding: 4px 0px !important;
  }

  .amount-holder>.right-pane,
  .sign-holder>.right-pane {
    display: inline-block;
    min-width: 16%;
    text-align: left;
    color: #797979 !important;
    font-weight: normal !important;
    border-bottom: 1px solid !important;
    padding: 4px 0px !important;
    padding-left: 6px !important;
    margin-left: 10px;
  }

  table.tbl-details,
  .tbl-details th,
  .tbl-details td {
    border: 1px solid !important;
    padding: 8px 12px !important;
    font-size: 11px !important;
  }

  .tbl-details th {
    font-size: 11px !important;
  }

  #spnPrintThermal table.tbl-details,
  #spnPrintThermal .tbl-details th,
  #spnPrintThermal .tbl-details td {
    padding: 4px 8px !important;
  }

  table.tbl-amount,
  .tbl-amount th,
  .tbl-amount td {
    border: 1px solid !important;
    padding: 3px 10px !important;
    font-size: 11px !important;
  }

  table.tbl-details {
    width: 100% !important;
  }

  table.tbl-amount {
    width: 30% !important;
  }

  #spnPrintThermal table.tbl-amount {
    width: auto !important;
    margin-left: 7px !important;
  }

  .tbl-details td,
  .tbl-amount td {
    font-weight: 800 !important;
    color: #000 !important;
  }

}

.container {
  padding: 0px
}

.HoverButtonRemove {
  color: #bb1414;
  /* background: white; */
  border-radius: 7px;
}

.HoverButtonRemove:hover {
  color: white;
  background: #bb1414 !important;
  transition: 0.5s;
}

.HoverButtonAdd {
  color: #04b64e;
  /* background: white; */
  border-radius: 7px;
}

.HoverButtonAdd:hover {
  color: white;
  background: #04b64e !important;
  transition: 0.5s;
}

.app-loader {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
  position: relative;
}



.sideTab {
  font: 13px Arial;
  display: inline-block;
  /* padding: 1em 2em; */
  text-align: center;
  color: #111827 !important;
  background: red;
  /* default color */
  padding: 0px 5px;
  /* "to left" / "to right" - affects initial color */
  background: linear-gradient(to left, #f9a11b 50%, #ffc443 50%) right;
  background-size: 200%;
  transition: .3s ease-out !important;
}

.sideTab:hover {
  background-position: left;
}

.css-o4b71y-MuiAccordionSummary-content {
  align-items: center !important;
}

.loaderDiv {
  position: fixed;
  opacity: 0.8;
  width: 100%;
  height: 100vh;
  z-index: 9999;
}

.add-button {
  background-color: #416bc7 !important;
  border-radius: 25px !important;
}

.grid-head-class {
  margin: 0px !important;
  padding: 5px !important;

}

.dropdown-item-text {
  padding: 0.25rem !important;
}

.dropdown-menu {
  padding: 0px !important;
}

.dropdown-item-text:hover {
  background: #ffcccc;
}

.displayFlex {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  font-weight: normal;
}

.thumb {
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  overflow: hidden;
  width: 20%;
  color: #000;
}

.thumb-gradient,
.thumb-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumb-gradient {
  background: linear-gradient(to bottom, rgba(255, 94, 94, 0.24) 0%, rgba(255, 0, 0, 0.56) 100%);
}

.thumb-anim {
  margin-top: auto;
  /* pushes all content to bottom */
  position: relative;
  transition: 0.5s;
  transform: translateY(100%);
}

.thumb-title {
  text-transform: uppercase;
  padding: 20px;
  transition: 0.5s;
  transform: translateY(-100%);
}

.thumb-description {
  padding: 10px 20px;
  transition: 0.5s;
  opacity: 0;
  flex: 1;
}

.thumb-author {
  padding: 20px;
  position: relative;
}


/* ANIMATE */

.thumb:hover .thumb-anim {
  transform: translateY(0);
}

.thumb:hover .thumb-title {
  transform: translateY(0%);
}

.thumb:hover .thumb-description {
  opacity: 1;
}