.RoundIndic {
    border-radius: 20px;
    border: 3px solid #D9D9D9;
    background: #B11EF6;
    width: 40px;
    text-align: center;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.RoundIndicActive {
    border-radius: 20px;
    border: 3px solid #D9D9D9;
    background: #3FD20BFC;
    width: 40px;
    text-align: center;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.RoundIndicDisable {
    border-radius: 20px;
    border: 3px solid #D9D9D9;
    background: #E0DAE3;
    width: 40px;
    text-align: center;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.vertHeight {
    margin: 0px 10px;
    width: 20%;
    height: 10px;
    border: 4px solid #B11EF6;
}

.vertHeightActive {
    margin: 0px 10px;
    width: 20%;
    height: 10px;
    border: 4px solid #3FD20BFC;
}

.vertHeightDisable {
    margin: 0px 10px;
    width: 20%;
    border: 4px solid #C4C4C4;
    height: 10px;
}

.innerCircle {
    color: white;
    font-size: 25px;
}

.mainDiv {
    display: flex;
    align-items: center;
    justify-content: center;
}

.line {
    height: 2px;
    width: 50px;
    background: #c61515;
    display: block;
    margin: 20px auto 20px;
}

.line::after {
    content: "";
    position: absolute;
    left: 50%;
    width: 150px;
    height: 2px;
    border-bottom: 2px dashed #c61515;
    margin-left: -75px;
}

.line-white {
    height: 2px;
    width: 50px;
    background: #ffffff;
    display: block;
    margin: 20px auto 20px;
}

.line-white::after {
    content: "";
    position: absolute;
    left: 50%;
    width: 150px;
    height: 2px;
    border-bottom: 2px dashed #ffffff;
    margin-left: -75px;
}